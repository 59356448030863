<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-02-01 10:51:29
 * @LastEditTime: 2021-02-03 11:40:31
 * @LastEditors: fely
-->
<template>
  <div class="skill-page">
    <el-dialog :visible.sync="meShow" width="80%" :show-close="false">
        <div class="top-header">
          请选择职位标签 ({{selectedSkill.length}}/8)
          <img src="~@/assets/images/chaWhite.png" @click="closeSkill"/>
        </div>
        <div class="content-box">
          <div class="skilllist">
            <div class="skill" v-bind:class="{selected:item.selected==true}" v-for="(item,index) in skillList" :key="index" @click="selectSkill(item,index)">
              {{item.name}}
            </div>
            <div class="skill" @click="editSkill">
              +自定义
            </div>
          </div>
          <div class="button-wrap">
            <div class="confirm" @click="submit">确认</div>
          </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      selectedSkill:[],
      skillList: [],
      meShow: false
    }
  },
  props: {
    list: {
      type: Array,
      default(){
        return []
      }
    },
    show: {
      type: Boolean,
      default(){
        return false
      }
    }
  },
  watch: {
    'list': {
      handler: function(val) {
        let arr = val
        this.skillList = arr.map( ele => {
          ele.selected = false
          return ele
        })
      }
    },
    'show': {
      handler: function(val) {
        this.meShow = val
      }
    }
  },
  methods: {
    selectSkill(item,index){
      if(this.selectedSkill.length>=8){
        this.$message.info('最多能选择8个')
      }else{
        this.skillList[index].selected = !this.skillList[index].selected
        this.$set(this.skillList,index,this.skillList[index])
        if(this.skillList[index].selected){
          this.selectedSkill.push(item.name)
        }else{
          this.selectedSkill = this.selectedSkill.filter(ele => ele != item.name)
        }
      }
      

    },
    closeSkill(){
      this.$emit('closeSkill')
    },
    submit(){
      this.$emit('updateSkill',this.selectedSkill?this.selectedSkill.join(','):'')
    },
    editSkill(){
      this.$prompt('请输入标签', '自定义', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPlaceholder: '输入标签',
          inputValidator: function(val){
            if(!val || val.length==0){
              return '内容不能为空'
            }else if(val.length>8){
              return '不能超过8个字'
            }else{
              return true
            }
          },
        }).then(({ value }) => {
          this.skillList.push({name:value,selected:false})
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '请输入'
          });       
        });
    }
  },
}
</script>

<style scoped lang="less">
/deep/ .el-dialog__header{
  padding: 0;
}
.top-header{
  
  background: #4E90FF;
  height: 60px;
  width: 100%;
  padding:0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #fff;
  img{
    width: 15px;
    height: 15px;
  }
}
.content-box{
  min-height: 80%;
  padding: 24px;
  .button-wrap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .confirm{
      cursor: pointer;
      width: 120px;
      height: 40px;
      background-color: #4E90FF;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
    }
  }
  .skilllist{
    display: flex;
    flex-wrap: wrap;
    .skill{
      cursor: pointer;
      flex-shrink: 0;
      padding: 8px 16px;
      background-color: #eee;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #999;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .selected{
      color: #4E90FF !important;
      background-color: #F1F6FF !important;
    }
  }
}
</style>
