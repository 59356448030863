<!--
 * @Description: 信息发布
 * @Author: fely
 * @Date: 2021-01-30 15:04:29
 * @LastEditTime: 2021-02-06 17:08:16
 * @LastEditors: fely
-->
<template>
  <div class="post-page">
    <div class="header">
      <top-header :white="true"></top-header>
    </div>
    <div class="content">
      <div class="wrapper">
        <div class="title-box">信息发布</div>
        <div class="post-content">
          <el-form ref="form" :rules="rules"  :model="form"  label-width="80px">
            <div class="sub-title">职位信息</div>
            <el-form-item label="职位名称" prop="title">
              <el-input v-model="form.title" placeholder="如：人力资源经理，请勿超过20个字" @change="handleNameChange"></el-input>
            </el-form-item>
            <el-form-item label="职位类别" prop="jobtypeId">
              <el-cascader
                clearable
                filterable
                v-model="form.jobtypeId"
                :options="posTypeOps"
                :props="{ expandTrigger: 'hover',value: 'id',label: 'name' ,children: 'secondlevel',emitPath:false }"
                :show-all-levels="false"
                 @change="selectPosType"
                 placeholder="请选择职位类别"
                 :key="cascKey"></el-cascader>
                
            </el-form-item>
            <el-form-item label="招聘类型" prop="recruit_object">
              <el-select v-model="form.recruit_object" placeholder="请选择招聘类型" @change="handleRecObjChange">
                <el-option label="社招" :value="1"></el-option>
                <el-option label="校招" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工作地址" prop="address">
              <el-input placeholder="请选择工作地址" @focus="handleWorkArea" v-model="form.address" readonly></el-input>
            </el-form-item>
            <div class="sub-title">职位要求</div>
            <el-form-item label="工作经验" prop="workyearid">
              <template v-if="form.recruit_object==1">
                <el-select v-model="form.workyearid" placeholder="请选择工作经验">
                <el-option v-show="item.workyear!='在校生'&& item.workyear!='应届生'" :label="item.workyear" :value="item.workyearid" v-for="item in expList" :key="item.workyearid"></el-option>
              </el-select>
              </template>
              <template v-else>
                <el-select v-model="form.workyearid" placeholder="请选择工作经验">
                <el-option v-show="item.workyear=='在校生'||item.workyear=='应届生'||item.workyear=='不限'" :label="item.workyear" :value="item.workyearid" v-for="item in expList" :key="item.workyearid"></el-option>
              </el-select>
              </template>
            </el-form-item>
            <el-form-item label="学历要求" prop="eduid">
              <el-select v-model="form.eduid" placeholder="请选择学历要求">
                <el-option :label="item.edutype" :value="item.eduid" v-for="item in eduList" :key="item.eduid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="薪资范围" prop="lowsalarie">
              <el-select v-model="form.lowsalarie" placeholder="请选择最低薪资" style="width: 30%;margin-right:10px">
                <el-option :label="item+'K'" :value="item" v-for="item in lowSalires" :key="item"></el-option>
              </el-select>
              <el-select v-model="form.highsalarie" placeholder="请选择最高薪资" style="width: 30%;margin-right:10px">
                <el-option :label="item+'K'" :value="item" v-for="item in highSalires" :key="item"></el-option>
              </el-select>
              <el-select v-model="form.months" placeholder="请选择发放月份" style="width: 30%;margin-right:10px">
                <el-option :label="item+'月'" :value="item" v-for="item in monthes  " :key="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位描述" prop="description">
              <el-input
                type="textarea"
                placeholder="请输入岗位职责、任职要求等描述，至少20个字，建议使用以下格式逐条列出：
岗位职责
1...
2...
任职要求
1...
2..."
                v-model="form.description"
                maxlength="2000"
                minlength="20"
                :rows="8"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item label="职位标签" prop="posKeywords" >
              <el-input placeholder="请选择职位标签" @focus="handleSkills" v-model="form.posKeywords" readonly></el-input>
            </el-form-item>
          </el-form>
          <div class="bottom-wrap">
            <div class="save-label">*认证成功的企业用户可以免费发布100条职位</div>
            <div class="save-btn" @click="savePos">保存并发布</div>
          </div>
        </div>
      </div>
      <div class="footer" style="margin-top: 30px">
        <p>©天津市亿慧网络科技有限公司 版权所有 <a class="beian"  target="_black" href="http://beian.miit.gov.cn">津ICP备20006513号</a></p>
        <div style="margin-left: 20px;">
          <a target="_blank" href="http://www.beian.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="~@/assets/images/police.png" style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">津公网安备 12011102001059号</p>
          </a>
        </div>
      </div>
    </div>
    <skill-list :list="skillList" :show="skillBoxShow" @closeSkill="closeSkill" @updateSkill="updateSkill"></skill-list>
    <work-area :show="areaShow" @closeArea="closeArea" @updateArea="updateArea"></work-area>
  </div>
</template>

<script>
import TopHeader from './topHeader'
import SkillList from './components/skill'
import WorkArea from './components/workarea'
import { getTocken } from '@/utils/auth'
import {getAllPosType,getAllEdu,getAllExp,getSkillByPid,getTypeByPName,savePos} from '@/api/position.js'
export default {
  name: '',
  data() {
    var validateSal = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择最低薪资'));
      } else if (!this.form.highsalarie) {
        callback(new Error('请选择最高薪资'));
      } else if(!this.form.months){
        callback(new Error('请选择发放月份'));
      } else{
        callback()
      }
    };
    return {
      form: {},
      rules: {
        title: [
          { required: true, message: '请输入职位名称', trigger: 'blur' },
          { maxlengtn: 20, message: '职位名称不能超过20个字', trigger: 'blur' }
        ],
        jobtypeId: [
          { required: true, message: '请选择职位类别', trigger: 'blur' }
        ],
        recruit_object: [
          { required: true, message: '请选择招聘类型', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请选择工作地址', trigger: 'submit'}
        ],
        workyearid:  [
          { required: true, message: '请选择工作经验', trigger: 'blur' }
        ],
        eduid: [
          { required: true, message: '请选择学历要求', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入职位描述', trigger: 'blur' },
          { min: 20, message: '至少20个字', trigger: 'blur' }
        ],
        posKeywords: [
          { required: true, message: '请选择职位标签', trigger: 'submit' }
        ],
        lowsalarie: [
          { validator: validateSal, trigger: 'blur' }
        ]
      },
      cascKey: 0,
      posTypeOps: [],
      eduList: [],
      expList: [],
      skillList: [],
      skillBoxShow: false,
      areaShow: false,
      monthes: [12,13,14,15,16,17,18,19,20,21,22,23,24],
      lowSalires: [1,2,3,4,5,6,7,8,9,10,15,20,30,40,50],
      highSalires: [2,3,4,5,6,7,8,9,10,15,20,30,40,50,60]
    }
  },
  components: {
    TopHeader,
    SkillList,
    WorkArea
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      getAllPosType().then(res => {
        this.posTypeOps = res.data.firstlevel.map( elefirst => {
          elefirst.secondlevel.map(eleSecond => {
            eleSecond.secondlevel = eleSecond.threelevel
            return eleSecond
          })
          return elefirst
        })
      })
      getAllEdu().then(res => {
        this.eduList = res.data
      })
      getAllExp().then(res => {
        this.expList = res.data
      })
    },
    selectPosType(id){
      getSkillByPid(id).then(res => {
        this.skillList = res.data
      })
    },
    handleSkills(){
      if(this.form.jobtypeId){
        this.skillBoxShow = true
      }else{
        this.$message.info('请选择职位类别')
      }
    },
    handleWorkArea(){
      this.areaShow = true
    },
    updateSkill(skill){
      this.form.posKeywords = skill
      this.closeSkill()
    },
    closeSkill(){
      this.skillBoxShow = false
    },
    closeArea(){
      this.areaShow = false
    },
    updateArea(obj){
      ({workcity:this.form.workcity,
      workcityname:this.form.workcityname,
      address:this.form.address,
      addressLongitude:this.form.addressLongitude,
      addressLatitude:this.form.addressLatitude,
      shangquanid:this.form.shangquanid,
      shangquanname:this.form.shangquanname} = obj)
      this.closeArea()
    },
    handleNameChange(e){
      getTypeByPName(e).then(res => {
        this.form.jobtypeId = res.data.id
        this.selectPosType(res.data.id)
        this.cascKey = this.cascKey +1
      })
    },
    handleRecObjChange(){
      delete this.form.workyearid
    },
    savePos(){
      if(getTocken()){
        this.$refs.form.validate( (valid) => {
         if(valid){
           this.form.releaseType = 1
           savePos(this.form).then(res => {
            if(res.code == 200){
              if(res.data.generalCertification){
                this.$alert('信息发布成功，您可以前往亿慧小秘书APP管理您的职位。', '保存成功', {
                  confirmButtonText: '确定',
                  callback: () => {
                    window.location.reload()
                  }
                });
              }else{
                this.$message.error('请前往亿慧小秘书APP完成企业认证')
              }
              
              // this.$router.push('/index')
            }
          })
         }
       })
      }else{
        this.$bus.emit("showLogin")
      }
       
      
    }
  },
}
</script>


<style scoped lang="less">
.post-page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header{
    position: relative;
    height: 60px;
    width: 100%;
    background-color: #fff;
  }
  .content{
    flex: 1;
    background-color: #F0F2F6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .wrapper{
      width: 980px;
      .title-box{
        width: 100%;
        height: 66px;
        color: #333;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
      .post-content{
        background-color: #fff;
        padding: 60px;
        .sub-title{
            font-size: 18px;
            font-weight: 500;
            line-height: 31px;
            margin-bottom: 30px;
            position: relative;
            z-index: 20;
            &:after{
              position: absolute;
              z-index: -1;
              content: '';
              display: block;
              bottom: 8px;
              left: 0;
              width: 70px;
              height: 5px;
              background-color: #4E90FF;
            }
        }
      }
    }
    .bottom-wrap{
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .save-label{
        color: #61687C;
        font-size: 14px;
      }
      .save-btn{
        cursor: pointer;
        margin-left: 15px;
        width: 120px;
        height: 36px;
        background-color: #4E90FF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}
.footer{
    // position: absolute;
    height: 27px;
    width: 100%;
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser,         older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: flex;
    justify-content: center;
    padding-bottom: 27px;
    font-size: 13px;
    box-sizing: border-box;
  }
  a.beian,.footer{
    color: #939295;
  }
  a.beian:hover{
    text-decoration:underline;
  }
/deep/ .el-cascader,.el-select{
  width: 100%;
}
/deep/ .el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner {
    border-color: #4E90FF;
}
/deep/ .el-select .el-input.is-focus .el-input__inner{
  border-color: #4E90FF;
}
/deep/ .el-select .el-input__inner:focus{
  border-color: #4E90FF;
}
/deep/ .el-input .el-input__inner:focus{
  border-color: #4E90FF;
}
/deep/ .el-textarea .el-textarea__inner:focus{
  border-color: #4E90FF;
}
</style>
