<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-02-01 17:37:01
 * @LastEditTime: 2021-02-03 15:24:59
 * @LastEditors: fely
-->
<template>
  <div class="area-page">
    <el-dialog :visible.sync="meShow" title="工作地点" width="580px" :show-close="true" @close="handleClose">
      <div class="area-content">
        <el-form label-width="80px" >
          <el-form-item label="公司地址">
            <el-input placeholder="请输入详细地址" v-model="address" @blur="handleAddrBlur"></el-input>
          </el-form-item>
          <el-form-item label="所在商圈">
            <el-select v-model="circle" placeholder="请选择商圈" @change="handleCircleChange">
              <el-option v-for="item in circles" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div id="map">
          <el-image :src="mapSrc">
            <div slot="placeholder" class="image-slot">
              请输入详细地址加载静态地图<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              请输入详细地址加载静态地图<span class="dot">...</span>
            </div>
          </el-image>
        </div>
        <div class="bottom-wrap">
          <div class="cancle" @click="handleCancle">取消</div>
          <div class="save" @click="handleSave">保存地址</div>
        </div>
      </div>
        
    </el-dialog>
  </div>
</template>

<script>
// getIdByName  getCircleByIP
import {geoCode,getCircleByIP,getIdByName} from '@/api/position.js'
export default {
  name: '',
  components: {},
  data() {
    return {
      circles: [],
      circle: '',
      circlename: '',
      location: [],
      map: null,
      geocoder: null,
      address: '',
      city: '',
      cityid: '',
      mapSrc: '',
      meShow: false
    }
  },
  props: {
    show: {
      type: Boolean,
      default(){
        return false
      }
    }
  },
  watch: {
    'show': {
      deep: true,
      handler: function(val) {
        this.meShow = val
      }
    }
  },
  methods: {
    reset(){
      this.circle = ''
      this.circlename = ''
      this.city = ''
      this.cityid = ''
      this.location = []
    },
    handleAddrBlur(){
      this.reset()
      geoCode(this.address).then(res => {
        let obj = res.geocodes[0]
        this.address = obj.province + ',' + obj.city + ',' + obj.district + ','+this.address
        this.city = obj.city
        this.location = obj.location.split(',')
        this.getCityId()
        this.getCircles()
        this.getMap()
      })
    },
    getMap(){
      this.mapSrc="https://restapi.amap.com/v3/staticmap?location="+this.location.join(',')+"&zoom=18&size=500*200&markers=mid,,A:"+this.location.join(',')+"&key=c7be8fc256c59994d86c81f55a9dbfd0"
    },
    getCircles(){
      getCircleByIP(this.location[1],this.location[0]).then(res => {
        this.circles = res.data
      })
    },
    getCityId(){
      getIdByName(this.city).then(res => {
        this.cityid = res.data.id
      })
    },
    handleCancle(){
      this.$emit('closeArea')
    },
    handleSave(){
      let obj = {}
      obj.workcity = this.cityid
      obj.workcityname = this.city
      obj.address = this.address
      obj.addressLongitude = this.location[0]
      obj.addressLatitude = this.location[1]
      if(this.circle){
        obj.shangquanid = this.circle
        obj.shangquanname = this.circlename
      }
      this.$emit('updateArea',obj)
    },
    handleCircleChange(e){
      this.circlename = this.circles.find((ele) => ele.id = e).name
    },
    handleClose(){
      this.$emit('closeArea')
    }
  },
}
</script>

<style scoped lang="less">
/deep/ .el-select{
  width: 100%;
}
/deep/ .el-dialog__header{
  padding-left: 40px;
  display: flex;
  justify-content: space-between;
}
/deep/ .el-dialog__title{
  font-size: 16px !important;
  font-weight: 600;
}
.area-title{
  text-align: left;
}
.area-content{
  padding: 24px 40px;
  #map {
    width: 100%; 
    height: 200px; 
  }  
}
.bottom-wrap{
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  .cancle{
    color: #999;
    font-size: 14px;
    cursor: pointer;
  }
  .save{
    cursor: pointer;
    margin-left: 13px;
    width: 96px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4E90FF;
    color: #fff;
  }
}
</style>
