/*
 * @Description: 
 * @Author: fely
 * @Date: 2021-01-30 16:46:48
 * @LastEditTime: 2021-02-06 16:27:04
 * @LastEditors: fely
 */
import { request } from './index.js';
import { getTocken } from '@/utils/auth.js'
// 保存职位
export function savePos(data) {
  data.token = getTocken()
  return request({
    url: '/api/pPost/savePost',
    method: 'POST',
    data: data
  })
}

// 获取所有工作类别
export function getAllPosType(data) {
  return request({
    url: '/api/pJobtype/getPJobtype',
    method: 'GET',
    data: data
  })
}

// 获取-学历
export function getAllEdu() {
  return request({
    url: '/api/pEducational/getPEducational',
    method: 'GET'
  })
}

// 获取-工作经验
export function getAllExp() {
  return request({
    url: '/api/pWorkyear/getPWorkyear',
    method: 'GET'
  })
}

// 获取-职位标签
export function getSkillByPid(jobtypeid) {
  return request({
    url: '/api/pPositionKeyword/getPPositionKeywordbyjobtypeid',
    method: 'GET',
    params: {
      jobtypeid,
      pagenum: 1,
      pagesize: 100
    }
  })
}

// 根据职位名称 获取职位类别
export function getTypeByPName(name){
  return request({
    url: '/api/pJobtype/getLikePJobtype',
    method: 'GET',
    params: {
      postTitle: name
    }
  })
}

// 根据城市名称 查 城市id
export function getIdByName(name){
  return request({
    url: '/api/sysCity/getidbyname',
    method: 'GET',
    params: {
      name
    }
  })
}

// 根据经纬度 查 商圈
export function getCircleByIP(latitude, longitude) {
  return request({
    url: '/api/sysCircle/selectPeripheryCircle',
    method: 'GET',
    params : {
      latitude,
      longitude
    }
  })
}

// 地理编码
export function geoCode(address){
  return request({
    url: '/v3/geocode/geo',
    method: 'GET',
    params: {
      address,
      output: 'JSON',
      key: 'c7be8fc256c59994d86c81f55a9dbfd0'
    }
  })
}

// 静态地图
export function getMapImg(params) {
  return request({
    url: '/v3/staticmap',
    method: 'GET',
    params: params
    
  })
}